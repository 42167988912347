@import "../Base/Variable.scss";

header{position: fixed; top: 0;width: 100%;z-index: 4;background-color: rgba($white,0); transition: all .1s linear;
 &.scrollHeader{background-color:rgba($white,0.8);backdrop-filter: blur(0.3125rem); transition: all .1s linear;z-index: 12;box-shadow:rgba($black,0.1) 0 0 1.25rem;
  .navbar{ padding-block-start: 0.5rem;
    &-menu{
      a{color: $black;}
    }
    &-brand{width: 3.3rem;display:block;
      .logo{display: block;}
      .homeLogo{display: none;}
      svg{height: 100%; width: 100%; object-fit: contain;}
    }
    &-icon{ border-inline-start: 0.0625rem solid $black;
      svg{
        path{fill: $black;}
      }
    }
  }
 }
}
.navbar{ transition: all .1s linear;
  $nav:&;
  &#{$nav}-expand-lg{padding-block-start: 2.125rem;}
  &-right{display: flex;align-items: center; margin-inline-start: auto; position: relative;
  }

  &-menu{font-family: $body-font-family; font-size: $base-font-size;display:flex; order: 1; position: relative;transition: all .7s;
    li{  
      a{margin-inline: 1.25rem;color: $white; padding-block: 0.938rem;display:block;
    }
     &.active{
      a{font-weight: $text-bold; color: $blue;}
    }
  }
    .servicemenu{position: relative;
      .serviceInnerPages{ position: absolute;width: 15.625rem;left: 0; top: 2.625rem; padding-block:1rem;opacity: 0; pointer-events: none;transition: all 100ms ease-in-out;
        ul{background-color: $black;border-radius: 0.313rem;margin-block-start:0.3rem;padding-block:1.2rem;
          li{
            a{font-size: $fs-14;color: $white;padding: 0rem;padding: 0.5rem 1.5rem;font-weight: $text-bold;margin-inline:0rem;
              &.serviceActive{background-color: rgba($white,0.2);}
            }
            &:hover{background-color: rgba($white,0.2);}
          }
        }
       
      }
      &:hover{
        .serviceInnerPages{opacity: 1; pointer-events: visible;transition: all 100ms ease-in-out;}
      }
    }
  }
  &-brand{
    .logo{display: none;}
    .homeLogo{display: block;}
  }
  &-icon{padding: 0.8125rem 0.9375rem; order: 2; border-inline-start: 0.0625rem solid $white;}
  .form{
    &-check-input{height: 2.375rem; width: 4.125rem!important;
      &:focus{box-shadow: none; border: 0;}
      &:checked{background-color: $black;border: 0.0625rem solid rgba($black,0.6);}
    }
  }
  &.dark{
    .navbar-icon{border-inline-start: 0.0625rem solid $black;
      svg{
        path{fill: $black;}
      }
    }
    .navbar-menu{
      a{color: $black;}
    }
    .navbar-brand{
      .homeLogo{display: none;}
      .logo{display: block;}
    }
  }

}


@media only screen and (max-width: 1199px){
.navbar{
  $nav:&;
  &#{$nav}-expand-lg{padding-block-start: 1rem;}
  &-menu {
    a {margin-inline: 0.8rem;font-size: $fs-14;}
    } 
  }
}


@media only screen and (max-width: 767px){
  header{
    &.scrollHeader{
      .navbar{padding-block-start: 0.313rem;
      &-brand{width: 2.4rem;}
      }
    }
  }
  .navbar{
    $nav:&;
    &-menu {display: block;position: fixed;top: 0; right: -1.875rem; transform: translateX(62.5rem);background-color:rgba($white,0.9);backdrop-filter: blur(0.5rem); width: 78vw; height: 100vh;padding:3rem 0rem 1rem 0rem;box-shadow: -0.813rem 1rem 3rem 1.188rem rgba($black,0.1); transition: all .3s linear;list-style: none;z-index: 2;
      li{
        a{color: $black;margin-inline: 0rem;padding-block: 0.6rem;}
        &.active{
          a{font-weight: $text-bold; color: $blue;}
        }
      }
      a{padding: 0.5rem 1.25rem;display: block;color: $blue; font-size: $fs-14;font-weight: $text-medium;transition: all .3s linear;}
      .mobilemenuClose{position: absolute; right: 1.5rem; top: -2.5rem;}
      &.open{transform: translateX(0rem); transition: all .3s linear;}
      .servicemenu{position: relative;
        .serviceInnerPages{ position: relative; left: auto; top: auto; width: auto;padding-block:0rem; pointer-events: none;transition: all 100ms ease-in-out;pointer-events: visible;opacity: 1;
          ul{background-color:transparent;border-radius: 0.313rem;margin-block-start:0.3rem;padding-block:0rem;
            li{color: $black;
              a{color: $black;padding: 0.5rem 0.5rem 0.5rem 2rem;font-weight: $text-light;
                &.serviceActive{background-color: rgba($sky,0.2);}
              }
            }
          }
        }
        &:hover{
          .serviceInnerPages{
            ul{background-color:transparent;}
          }
        }
      }
    } 
    &-brand{max-width: 3rem;display: flex;align-items: center;justify-content: center;
      svg{height: 100%; width: 100%; object-fit: contain;}
      }
    &-icon{padding: 0.5rem;}
    }
  }



  
  
