@import url('https://fonts.googleapis.com/css2?family=Chivo:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

//Font-family variables
$heading-font-family: 'Chivo', sans-serif;
$body-font-family: 'Inter', sans-serif;


//Font-size variables
$base-font-size: 1rem;
$h1-fs:5rem; //153px
$h2-fs:10.625rem; //170px
$h3-fs:3.438rem; //55px
$h4-fs:2.5rem; //40px
$fs-100:6.25rem; //100px
$fs-11: 0.688rem;
$fs-14: 0.875rem;
$fs-18: 1.125rem;
$fs-19: 1.188rem;
$fs-20: 1.25rem;
$fs-22: 1.375rem;
$fs-25: 1.563rem;
$fs-30: 1.875rem;
$fs-35: 2.188rem;
$fs-40: 2.5rem;
$fs-45: 2.813rem;
$fs-80: 5rem;


//Font-weight variables
$base-font-weight: 400;
$text-light: 500;
$text-medium: 500;
$text-bold: 600;


//Color Variables
$white: #fff;
$black: #000;
$gray:#222222;
$gray2:#353434;
$bluishGray:#98a2b3;
$BluishBlack: #00071E;
$sky: #6BAFFF;
$blue: #0a1d56;
$green: #90ff21;
$brown: #0B0B0B;
$purple:#EDDAFF;


@media only screen and (max-width: 1299px) {
  $base-font-size: 1rem;
  $h1-fs:6.563rem;
  $h2-fs:10.625rem;
  $h3-fs:4.063rem;
}


//$grid-breakpoints: (

//   //Extra small screen / phone
//   xs: 0,

//   // Small screen / phone
//   sm: 576px,

//   // Medium screen / tablet
//   md: 768px,

//   // Large screen / desktop
//   lg: 1000px,

//   // Extra large screen / wide desktop
//   xl: 1200px,
//   xxl: 1440px,

// );