@import "../Base/Variable.scss";


//-----------------Inner Line design for each Section--------------------
.innerLine{position: absolute;left: 0; top: 0; width: 100%; height: 100%;display: flex; justify-content: space-around;pointer-events:none;
  div{width: 0.0625rem; background-color: rgba($gray,0.1);}
}

//-----------------Only Global Banner CSS--------------------
.globalBanner{ position: relative; padding: 7rem 0rem 0rem 0rem; 
  h1{font-size: 9rem; line-height: 1.2;}
  &:after{content:'';width: 11.6875rem; height: 9.5rem;background-image: url(../../Assets/Images/HomeImages/CurveLine.svg);position: absolute; top: 0;right: 25%;opacity: 0.3;}
  &:before{content:'';width: 6.375rem; height: 21.6875rem;background-image: url(../../Assets/Images/HomeImages/BottomCurve.svg);position: absolute; top: calc(100vh - 19.8125rem);right: 0;z-index: 2;opacity: 0.3;}
  h5{font-size: $fs-30;}
  .container{position: relative; height: 100%;}
  &-img{height: 29.75rem; width: 100%; position: relative;margin-block-start:2.125rem;border-radius: 0.313rem; overflow: hidden;z-index: 2;
    img{height: 100%; width: 100%; object-fit: cover;}
  }
  &-text{height: 100%; display: flex; flex-direction: column; justify-content: center;padding-inline-start: 5.5rem;position: relative; z-index: 2;}
  .phNumber{left: 1.45rem; bottom: 5.24rem;}
  &.inner{
    h1{font-size: $fs-80; font-family: $heading-font-family;line-height: 5.688rem;}
    .globalBanner-text{
      &.head{padding-block-start: 5rem;}
    }
  }
}


.businessIntro{background-color: black;padding:12.58rem 0rem 5.875rem 0rem; margin-block-start: -4.19rem;
  &-wrapper{padding-inline: 5.5rem;}
  h2{display: inline;
    span{font-size: 4.5rem;text-transform: capitalize;display: inline-block;-webkit-text-fill-color: rgba($white,0.6);}
    .vertical{transform: rotate(270deg);transform-origin: 2.125rem 1.25rem; display: inline-block;color: black;-webkit-text-fill-color: rgba($white,0.1);text-transform: uppercase;font-size: 3.75rem;}
  }
  &.services{
    h3{font-size: $h3-fs;}
  }
}



//-----------------Only Service CSS--------------------
.homeServices{background-color: $black;color: rgba($white,0.6);padding: 1.875rem 0rem 3.625rem 0rem; position: relative; z-index: 1;

  &-info{margin-block-start: 3.3125rem !important;
  &-about{padding-inline-start: 2.5rem;
    p{padding-block-end: 2.25rem;position: relative;display:inline-block;font-family: $heading-font-family;
      span{margin-inline-end: 3.4375rem; color: rgba($white,0.5); font-size: $fs-20;position: relative;
        &:after{content: ''; position: absolute; left: 50%; transform: translateX(-50%); bottom: -1.4rem; width: 0.5rem;border-radius: 50%; height: 0.5rem;background-color: $gray2;}
        &:last-child{margin-inline-end: 0rem;}
      }
      &:after{content: ''; position: absolute; left: 0; bottom: 1.25rem; width: 100%;height: 0.0625rem;background-color: $gray2;}
    }
    ul{background: linear-gradient(0deg, #030610 -0.01%, rgba(4, 8, 19, 0.00) 82.26%);height:28.9375rem;overflow-y: scroll;border-block-start:0.0625rem solid rgba($white,0.3);
      &::-webkit-scrollbar {
        width: 0.125rem;background-color: rgba($white,0.3);
      }
      &::-webkit-scrollbar-thumb {
        background-color: $green;
      }
      li{display: flex;align-items: center; padding: 1.875rem;border-block-end:0.0625rem solid rgba($white,0.3);transition: all 300ms ease-in-out;cursor: pointer;
        span{margin-inline-end: 2.125rem;font-size: $fs-25;display: flex;align-items: center;}
        h5{font-size: $fs-25;padding-inline-end: 2.6875rem;margin-block-end: 0rem; max-width: 16.1875rem; flex: 0 0 16.1875rem; font-family: $heading-font-family; font-weight: $base-font-weight;}
        p{border:0rem;padding-block-end: 0rem;font-family: $body-font-family;
          &:after{display: none;}
        }
        div{display: none;align-items: center;}
      }
    }
  }
}
  &-image{width: 100%;max-height: 33.125rem;border-radius:0.313rem;overflow: hidden;
    img{height: 100%; width: 100%; object-fit: contain;}
  }
  .services{color: $white; font-size: $fs-20;font-family: $heading-font-family;}
  .servicesName{color: rgba($white,0.5);font-size: $fs-45;font-family: $heading-font-family;line-height: 1;}
  .learnMore{font-family: $body-font-family;display: flex; flex-direction: column; justify-content: end; position: relative;
    a{@extend .services; margin-block-start:0.4375rem;font-size: $base-font-size;
      span{margin-inline-start: 0.5625rem;}
    }
    &::after{content: '';height: 100%; width: 0.0625rem; background-color: rgba($white,0.20);position: absolute;left: 0; top: 0;}
  }
}


//-----------------Only Industry CSS--------------------
.industry{position: relative; z-index: 1;background-color: $black;font-family: $heading-font-family;
  &-wrapper{background-color: $white;padding-block: 6rem;border-radius: 2.5rem 2.5rem 0rem 0rem;}
  &-tab{margin-block-start: 4.313rem!important;z-index: 2;position: relative;}
  &-image{width: 50%;display:flex; justify-content: start; align-items: center;
  img{height: 100%; width: 100%; object-fit: contain;}
}
  &-description{width: 50%;
    h5{color: $blue; font-size: $fs-35;margin-block-end: 1rem;position: relative;
    &:after{content: ''; height: 4.188rem; width: 4.188rem; position: absolute; background-color: rgba($sky,0.1); border-radius: 50%; left: -1.5rem; top: 50%; transform: translateY(-50%);}
    }
    p{margin-block-end: 1.563rem!important;}
    a{color: $black;font-family:$body-font-family;
      span{margin-inline-start: 0.375rem;}
    }
  }
  .nav{
    &-pills{height: 25rem;display: block;overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0.125rem;height: 0.125rem; background-color: rgba($black,0.3);
      }
      &::-webkit-scrollbar-thumb {
        background-color: $black;
      }
    }
    &-item{margin-block-end: 2.3rem;}
    &-link{color: rgba($black,0.5);font-size: $fs-22;padding:0rem;display:flex;
      span{margin-inline-end: 1.5rem;display: block;}
      &.active{color: $black!important;background-color: $white!important;font-weight: $text-bold;}
      &:hover{color:$blue;}
    }
  }
  
}

.industry{
  &.serviceInner{background-color: $white;
    .nav-pills{overflow-y: auto;}
  }
}

.itSolutions{padding-block-start: 7.625rem;
  h3{text-align: center;margin-block-end: 1.25rem;}
  p{text-align: center;}
  ul{display: flex; flex-wrap: wrap; grid-column-gap: 10rem;margin-block-start:3.938rem;counter-reset: my-sec-counter;
    li{width:  calc((100% - 20rem) / 3); margin-block-end: 2rem;padding-block-start:4.938rem;position: relative;
      span{font-family: $heading-font-family;font-size: $h1-fs; font-weight: $text-bold;position: absolute;top: 0;line-height: 1;z-index: -1;opacity: 0.5;}
      h5{margin-block-end: $fs-25;}
      p{text-align: start;}
      &::before{counter-increment: my-sec-counter;content: "0" counter(my-sec-counter);    background: -webkit-linear-gradient(0deg, #F3DAFF 1.4%, #BBE2FF 98.95%);-webkit-background-clip: text;-webkit-text-fill-color: transparent; font-size: 5rem; font-weight: $text-bold;position: absolute; top:0;left: 0;opacity: 0.5;z-index: -1;font-family: $heading-font-family;}
    }
  }
  &-image{height: 15.625rem;position: relative;margin-block-start:5.5rem;
    &-inner{position: absolute; width: 100%; height: 37.5rem;z-index: 2;border-radius: 0.313rem; overflow: hidden;
      img{ height: 100%; width: 100%; object-fit: cover;}
    }
  }
}

//-----------------Only Develop Products CSS--------------------
.products{position: relative; z-index: 2; background-color: $black;
  &-wrapper{background-color: $white;padding-block-start: 3.75rem;border-radius: 2.5rem 2.5rem 0rem 0rem;}
  &-swiper{margin-block-start: 4.25rem;height:13.25rem; position: relative;
  .swiper{position: absolute;width: 100%; left: 0;top: 0;z-index: 2;
    &-slide{position: relative;
      div{width: 100%; height: 23.125rem;position: relative;
        img{height: 100%; width: 100%; object-fit: cover;}
        &:after{content: ''; height: 100%; width: 100%; position: absolute; left: 0; top: 0; background-color: rgba($black,0.4);}
      }
      p{position: absolute;width: 100%; bottom: 0; left: 0; z-index: 2;color: $white;font-size: $fs-35;font-family: $heading-font-family; line-height: 1;padding: 2.063rem;}
    }
  }
  }
}


//-----------------Only Technologies CSS--------------------
.technologies{position: relative; z-index: 1;background-color: $black;
  &-wrapper{background-color: $white;padding-block-start: 3.75rem;border-radius: 2.5rem 2.5rem 0rem 0rem;
    h3{text-align: center;}
    p{text-align: center;}
  }
  &-images{width: 100%; height: 42.688rem;display:flex;justify-content:center; align-items: center;
  img{height: 100%; width: 100%; object-fit: contain;}
}
}

.latestBlog{background-color: $black;padding: 9.25rem 0rem 3.5rem 0rem;margin-block-start: -4.19rem;
  &-title{color: $white;margin-block-end: 1.2rem;font-size: $fs-18; line-height: 1.2;}
  &-img{height: 13.4375rem; width: 100%;margin-block-end:3.125rem;
    img{height: 100%; width: 100%; object-fit: cover;}
  }
  &-box{display: flex; flex-direction: column; justify-content: space-between;}
}


.contactUs{background-color: $black;padding-block-start: 11.688rem; margin-block-start: -4.19rem;
  &-details{display: flex; color: rgba($white,0.6); margin-block-end: 3.75rem;
    a{color: rgba($white,0.6);}
    &-head{font-size: $fs-22;}
  }
  &-svg{margin: 0.6rem 1rem 0rem 0rem;width: 2.5rem; height: 2.5rem; flex: 0 0 2.5rem;
    svg{height: 100%; width: 100%; object-fit: contain;}
  }
  button{display: block; text-align: center; max-width: 14.875rem;color:rgba($white,0.5);border:0rem;width: 100%;
    &.send{background-color: $white; padding: 0.938rem 1.25rem; border-radius: 5px;color: $black; font-size: $fs-18;}
  }
}

.Location{background-color: $black;padding-block: 6.25rem; 
  h3{text-align: center;}
  p{margin-inline-start: 1.25rem;text-align: center;}
  &-map{height: 28.5rem;margin-block-start:2.813rem; border-radius: 0.625rem; overflow: hidden;
    iframe{width: 100%; height: 100%;}
  }
  
}



.application{padding-block-start: 4.813rem;
  h3{margin-block-end: 1.875rem;}
}

.points{padding: 7.5rem 0rem 4.125rem 0rem;
  &.dark{background-color: $black;
    ul{
      li{
        span{color: $white;}
      }
    }
  }
  h3{ margin-block-end: 2.063rem;}
  ul{counter-reset: my-sec-counter;
    li{display: flex;margin-block-start:4.5rem; align-items: center; position: relative;padding-inline-start:8rem;
      h3{margin-inline-end: 1.438rem;margin-block-end: 0rem;}
      span{font-weight: $text-bold;color: $blue;width: 16.25rem;display: flex;flex: 0 0 16.25rem; font-size: $fs-20; margin-inline-end: 1.875rem;}
      &::before{counter-increment: my-sec-counter;content: "0" counter(my-sec-counter) "";    background: -webkit-linear-gradient(0deg, #F3DAFF 1.4%, #BBE2FF 98.95%); -webkit-background-clip: text;-webkit-text-fill-color: transparent; font-size: $h3-fs; font-weight: $text-bold;position: absolute; top: 50%;transform: translateY(-50%); left: 0;line-height: 1;font-family: $heading-font-family;}
    }
  }
  .development{ height: 30rem; width: 100%; position:absolute;bottom:0; left: 0; z-index: 2; border-radius: 0.313rem; overflow: hidden;
    img{ width: 100%; height: 100%; object-fit: cover;}
  }
}


.serviceCarousel{padding-block-start: 5.625rem;
  h3{text-align: center;}
 
}

.imageSlider{ position: relative;padding: 4.375rem 0rem 8.75rem 0rem;}
.swiper{
  &.mySlider{padding-block: 1.25rem;overflow-y: visible;
    .swiper-slide{opacity: 0;transition: all 1s;
      &-visible {opacity: 0.4;}
      &-active{opacity: 1;}
      .slide{
        &Wrapper{position: relative;filter: drop-shadow(12px 2px 40px rgba(0, 0, 0, 0.48));
          &:before{content: '';background-color: $black;width: calc(100% + 4.5rem);height: 75%; position: absolute;left: -2.5rem;bottom: 0; border-radius:0.375rem ;}
        }
        &Image{width:100%;height: 0;padding-block-start: 75%;position: relative;z-index: 2;
          &Box{position: absolute;width: 100%;height: 100%;left: 0;top: 0;border-radius:0.3125rem;overflow: hidden;
            img {display: block;width: 100%;height: 100%;object-fit: cover;border-radius:0.375rem ;}
          }
        }
        &Text{padding-block: 2.25rem;position: relative;z-index: 2;
          h4{color: $white;font-family: $heading-font-family;font-size: 3rem;font-weight: $base-font-weight;line-height: 1.25;}
          p{text-align: left;color: rgba($white,0.6);}
        }
      }
    }
  }
}

.platform{padding-block: 4.375rem;background-color: $black;
  h3{color: $white; text-align: center;}
  p{text-align: center;}
  .swiper{
    &.mySlider{
      .swiper-slide{
        .slide{
          &Wrapper{
            &:before{background-color: $white;}
          }
          &Text{
            h4{color: $black;}
            p{color: rgba($black,0.6);}
          }
        }
      }
    }
    
  }
}

.proccess{
  &-list{
    .row{margin-block-start: 96px;}
    &.onlyText{
      .proccess-details{margin-block-end: 4.375rem;}
    }
  }
  &-image{display: flex; justify-content: center; align-items: center; height: 18.50rem;
    img{width: 100%; height: 100%; object-fit: contain;}
  }
  &-details{display: flex;
    h3{margin-inline-end: 1.438rem;margin-block-end: 0rem;}
    &-head{font-weight: $text-bold;color: $blue;font-size: $fs-20;margin-block-end: 0.375rem;}
  }
}

.bottomSpace{padding-block-end: 14rem;}
.newsLetter{background-color: $black;padding: 4.125rem 0rem 5.313rem 0rem;
  h3{color: $white;margin-block-end: 0.688rem; }
}

.seo{
  &-list{display: flex; flex-wrap: wrap;background: linear-gradient(90deg, #F3DAFF 0%, #BBE2FF 100%), #D9D9D9;width: 100%;margin:4rem 0rem 6.25rem 0rem;
    li{display: flex; justify-content: center;width: 25%; margin-block: 1.563rem;
      p{color: $brown; font-size: $fs-20;font-weight: $text-medium;}
    }
    &-number{position: relative;
      h3{font-size: 8.125rem; color: rgba($blue,0.08);margin-block-end: 0rem;}
      &-svg{position: absolute;bottom: 1rem; left: 0;height: 3.75rem; width: 3.75rem;
        svg{height: 100%; width: 100%; object-fit: contain;}
      }
    }
    &-box{display: flex; flex-direction: column; padding-inline: 1.375rem;width: 16.25rem; height: 16.25rem;  border-radius: 0.313rem;transition: all 100ms ease-in-out;
      &:hover{background-color: $white; border: 0.065rem solid $sky; transform: scale(1.2);transition: all 100ms ease-in-out;margin-top: -46px;}
    }
  }
}

.careerForm{
  .form{
    &-switch{display: none;}
    &-control{background-color: #e5e5e5!important;border:1px solid $purple!important;padding: 0.625rem 1rem 0.625rem 1rem !important;color:$black !important;line-height:1.5;font-family: $heading-font-family;
      &::placeholder{color:rgba($black,0.5)!important;}
      &:focus{box-shadow: none!important;border: 0.0652rem solid rgba($black,0.8)!important;}
    }
    &-floating{
      :after{display: none;}
    }
  }
  .file{position: absolute; left: 1rem; top: 0;height: 100%; width: 100%;opacity: 0;}
  .upload{background: linear-gradient(90deg, #F3DAFF 1.4%, #BBE2FF 98.95%), #C0E1FF;border-radius:  0.375rem;height: 100%;display: flex; align-items: center; justify-content: center;color: $black;padding:0.625rem 1rem;
    span{margin-inline-start: 0.625rem; height: 1.25rem; width: 1.25rem;
      svg{height: 100%; width: 100%; object-fit: contain;}
    }
  }
  &-submit{width: 100%; background-color: $black; color: $white; border: 0.065rem solid $black;padding: 0.625rem;border-radius:  0.375rem;}
}
.career{background-color: black;padding:12.58rem 0rem 5.875rem 0rem; margin-block-start: -4.19rem;
  .vertical{transform: rotate(-90deg) translateY(5rem);font-size: 9.063rem;transform-origin: left bottom;margin-bottom: 0; line-height: 0.75;height: 100%;}
  .sideText{transform: translateX(-9.188rem);font-size: 5.625rem;line-height:1;}
  p{margin-block-start: 1.563rem;}
}

.faq {padding-block-start: 4.813rem;
  h3{font-size: $fs-100;text-align: center;line-height: 1.2;}
  .accordion{margin-block: 3.75rem;
    &-item{border: 0.065rem solid rgba($black,0.1); border-inline: 0rem; border-radius:0rem;
      :last-of-type{
        .accordion-button{
          &.collapsed{border-radius: 0rem;}
        }
      }
    }
    &-body{border-radius:0rem;
      ul{list-style: disc;padding-inline-start: 1.188rem;margin-block-start: 0.75rem;
        li{margin-block-end: 0.25rem;color: rgba($black,0.5);}
      }
      a{color: $black; font-family: $heading-font-family;}
    }
    &-header{-webkit-text-fill-color: $black; line-height: 1;font-size: $fs-25;border-radius:0rem;
      span{margin-inline-end: 1.5rem;background-color: rgba(0, 0, 0, 0.1);height: 2.875rem;width: 2.875rem;border-radius: 50%;display: flex;align-items: center;justify-content: center;flex: 0 0 2.875rem;}
    }
    &-button{ color: $black;font-size: $fs-25;padding: 1.62rem;border-radius:0rem;
      &:focus{box-shadow: none; border: 0rem;}
      &:not(.collapsed){background: linear-gradient(90deg, #F3DAFF 1.4%, #bbe1ffc2 98.95%), #c0e1ffad;}
      :after{background-image: url(../../Assets/Images/Svg/AccordionSvg.js);display: none!important;}
    }
  }
  .rotate{position: absolute;right:0; bottom: 0;}
  &.careerPage{padding-block-start: 0rem;}
}
.careervideo{background-color: $black;padding:3.75rem 0rem 5.625rem 0rem ;
  &-story{width: 100%!important; height: 43.75rem!important;padding: 2.188rem 0rem 5.813rem 0rem;
    iframe{border-radius: 0.625rem; overflow: hidden;
      .video-stream{border-radius: 0.625rem; overflow: hidden;}
    }
  }
  h3{margin-block-end: 1.563rem;}
}
.recognition{padding: 3.125rem 0rem 5.938rem 0rem;
  h3{font-size: 9rem;line-height: 1.2;margin-block-end: 3.625rem;}
  .certified{justify-content: center; margin-block-start: 3.625rem;display: flex; flex-wrap: wrap;
    li{display: flex; align-items: center; justify-content: center;padding:20px;
      .certificate{height: 10.5rem; width: 10.5rem; background-color: rgba($black,0.4);display: flex; align-items: center; justify-content: center;
      img{height: 100%; width: 100%; object-fit: contain;}
      }
    }
  }
}





