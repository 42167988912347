@import "./Variable.scss";
html, body{overflow-x: hidden; padding: 0; margin: 0;
  &::-webkit-scrollbar {
    width: 0.625rem;background-color: rgba($black,0.8);
  }
  &::-webkit-scrollbar-thumb {
    background-color: $blue; border: 0.0625rem solid rgba($white,0.5); border-radius: 2.5rem;
  }
}
body{font-size: $base-font-size;line-height: 1.5;font-weight: $base-font-weight;font-family: $body-font-family;}
*{box-sizing: border-box;}
.container{max-width: 1440px;}
a{text-decoration: none;}
p{margin-block-end: 0px;
  &.primary{color: rgba($white,0.7);}
  &.secondary{color: rgba($black,0.8);}
}
ul{list-style: none;padding-inline-start: 0px;margin-block-end: 0rem;}

/* ~~~~~~~~~~~~~~~~~~~~ Typography~~~~~~~~~~~~~~~~~~~~~~~*/
h1{color: $white; font-size: $h1-fs;line-height: 1;font-weight: $text-bold;}
h2{font-size: $h2-fs;line-height: 0.75; font-weight: $text-bold; background: -webkit-linear-gradient(#eeeeee57, #0c0c0c);-webkit-background-clip: text;-webkit-text-fill-color: transparent;text-transform: uppercase;font-family: $heading-font-family;margin-block-end: 0rem;}
h3{color: $BluishBlack; font-size: $h3-fs;line-height: 1.2;font-weight: $text-bold; font-family: $heading-font-family;}
h4{font-size: $h4-fs;}
.gradient{ background: -webkit-linear-gradient(0deg, #F3DAFF 1.4%, #BBE2FF 98.95%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;}



/* ~~~~~~~~~~~~~~~~~~~~ Buttongraphy~~~~~~~~~~~~~~~~~~~~~~~*/
.primary-button{height: 9.75rem; width: 9.75rem; border-radius: 50%;border: 1px solid rgba($white,0.1);display: flex; align-items: center; justify-content: center; font-size: $fs-18; color: $white;font-family: $heading-font-family;box-shadow: (-12px 5px 65px rgba(4, 205, 0, 0.21));position: relative;
&::after{content:'';background-image: url(../../Assets/Images/HomeImages/buttonArrow.svg);position: absolute; top: 3.375rem;right: 1.75rem;height: 1.2rem; width: 1.2rem;background-repeat: no-repeat;}
}
.secondary-button{height: 9.75rem; width: 9.75rem; border-radius: 50%;border: 1px solid rgba($black,0.5);display: flex; align-items: center;  font-size: $fs-18; color: $black;font-family: $heading-font-family;position: relative;padding-inline-start:1.875rem;overflow:hidden;background-color:$white;z-index: 2;transition: all 300ms ease-in-out;
  &::after{content:'';background-image: url(../../Assets/Images/HomeImages/BlackbuttonArrow.svg);position: absolute; top: 4.375rem;right: 1.4rem;height: 2rem; width: 2rem;background-repeat: no-repeat;transition: all 300ms ease-in-out;
  }
  &::before{content:'';background-color: $green;position: absolute;left: -10px;bottom: -31px;height: 4.563rem;width: 5.5rem;background-repeat: no-repeat;border-radius: 50%;transition: all 300ms ease-in-out;z-index: -1;
  }
  &:hover{
    &:before{height: 150%; width: 150%;transition: all 300ms ease-in-out;}
  }
}

.secondary-button{
  &.dark{border: 0.0625rem solid rgba($white,0.1);background-color: $black;color: $white;flex:0 0 9.75rem;
    &:after{background-image: url(../../Assets/Images/HomeImages/buttonArrow.svg);top: 3.375rem;right: 2.2rem;}
    &:hover{color: $black;
      &:after{background-image: url(../../Assets/Images/HomeImages/BlackbuttonArrow.svg);top: 2.375rem;right: 2.2rem;}
    }
  }
}




.form{
  &-switch{display: none;}
  &-control{background-color: rgba($white,0.2)!important;padding: 0.625rem 1.4rem 0.625rem 2.625rem !important;color:$white !important;line-height:1.5;border: 0.0652rem solid rgba($white,0.2)!important; font-family: $heading-font-family;
    &::placeholder{color:rgba($white,0.5)!important;}
    &:focus{box-shadow: none!important;border: 0.0652rem solid rgba($white,0.8)!important;}
  }
  &-floating{
    :after{display: none;}
  }
}


.footer{
  &-input{max-width: 25rem;
    span{background-color: rgba($white,0.5);border: 0rem;padding-inline: 1.063rem;}
  }
}
.rotate{position: relative;height:8.875rem; width: 8.875rem;margin-block-start:5.125rem;
  &-wrapper{animation: loading 15s linear infinite;z-index: 2;position: relative;cursor: pointer;height: 8.938rem; width: 8.938rem;
    svg{height: 100%; width: 100%; object-fit: contain;}
    @keyframes loading {
      0% { 
        transform: rotate(0); 
      }
      100% { 
        transform: rotate(360deg);
      }
    }
    &:hover {
      animation-play-state: paused;
    }
  }
  &-meeting{position:absolute; right: 3.75rem;bottom: 6.25rem;}
  &-link{position:absolute; right: 0.1875rem;top: 0;width: 100%; height: 100%;color: $black; display: flex; align-items: center; justify-content: center;font-size: $fs-22;font-weight: $text-bold; font-family: $body-font-family;text-transform: uppercase;text-align: center;}
  &-arrow{position:absolute;right: 3.25rem; top: -2.5rem;}
  &.white{margin-block-start: 0rem;
    .rotate-link{color: $white;}
  }
}


.phNumber{font-size: $fs-22;color: $black;position:absolute;left:0.75rem; bottom: 6.25rem; transform: rotate(270deg);transform-origin:left;
  span{margin-inline-end: 0.625rem;
    svg{transform: rotate(45deg);}
  }
}






