@import "../Base/Variable.scss";

.homeServices{
  &.servicePage{padding-block-start: 10.69rem;margin-block-start: -4.19rem;}
}

.industry{
  &.servicePage{
    .industry-wrapper{padding-block-end: 0rem;}
  }
}

.technologies{
  &.servicePage{background-color: $white;
  }
}

.products{
  $this:&;
  &.servicePage{background-color: $white;
    #{$this}{
      &-wrapper{padding-block-start: 8rem;}
    }
  }
}

.More{background-color: $black; padding:17rem 0rem 3.75rem 0rem;
  h3{color: $white;}
}

.stage{padding-block-start: 1rem;
  $this:&;
  &.servicePage{ color: $black;background-color: $white;
    #{$this}{
      &-growth{height:60vh;
        li{border-inline-start: 0.0625rem solid rgba($black,0.2);
          &:last-child{border-inline-end: 0.0625rem solid rgba($black,0.2);}
        }
      }
      &-progress{
        p{text-align: center;margin-block-start: 5rem;}
      }
      &-head{color: $black;text-align: center;font-size: $h3-fs;}
      &-name{color: $black;}
      &-description{border-block-start: 0.0625rem solid rgba($black,0.2);
        &:after{ background-color: $black;filter: blur(0rem);}
      }
      &-description{
        a{color: $black;}
      }
    }
  }
}




