@import "../Base/Variable.scss";


Footer{position: relative; z-index: 1;background-color: $black;font-family: $heading-font-family;padding: 3.125rem 0rem 4.875rem 0rem;font-family:$body-font-family; border-block-start: 0.065rem solid rgba($white,0.2);
  .footer{display: flex;justify-content:space-between;
    &-logo{max-width: 11rem; height: 9.75rem;display:flex; justify-content: start;
      svg{height: 100%; width: 100%; object-fit: contain;}
    }
    &-list{display: flex;padding-inline:2.188rem!important;margin-block-end: 1.25rem;
    li{padding-inline-end: 1.875rem;
      &:last-child{padding-inline-end: 0rem;}
      ul{
        li{padding-inline-end: 0rem;margin-block-end:0.625rem;line-height:1.3;
          &:first-child{font-size: $fs-18; color: $white; margin-block-end: 1.125rem;}
          a{color: rgba($white,0.5);
            &:hover{color: $white;}
          }
        }
      }
      }
    }
    &-social{display: block;
      li{margin-block-end: 1.25rem;}
      &-mob{display: none;}
    }
    &-input{max-width: 25rem;
      span{background-color: rgba($white,0.5);border: 0rem;padding-inline: 1.063rem;}
    }
    &-copyright{display: flex;margin-inline-start:1.5rem;
      span{margin-inline-end: 0.725rem;
        a{color: rgba($white,0.7);
          &:hover{color: $white;}
        }
        &:first-child{
          a{font-weight: $text-bold;}
        }
      }
    }
  }
}



@media only screen and (max-width: 1199px){
  Footer {
    .footer{
      &-logo{max-width: 5rem;
        height: 3.75rem;}
    } 
  }
}

@media only screen and (max-width: 991px){
Footer{
  .footer{
    &-copyright{margin-inline-start:0rem;margin-block-start:1rem;}
  }
}
}


@media only screen and (max-width: 767px){
  Footer {padding: 3rem 0rem 1rem 0rem;
    .footer{
      &-logo{max-width: 3rem; flex:0 0 3rem; height: 3.75rem;}
      &-list{display: flex;padding:0rem 0rem 0rem 1rem!important; flex-wrap: wrap;
        li{padding-inline-end: 12px; width: 50%;
          &:last-child{padding-inline-end: 0rem;}
          ul{margin-block-end: 1rem;
            li{padding-inline-end: 0rem;margin-block-end:3px;line-height:1.2;width: 100%;
              &:first-child{font-size: $fs-14; font-weight: $text-bold; color: $white; margin-block-end: 12px;}
              a{word-wrap: break-word;}
            }
          }
        }
      }
      &-input{max-width: 100%;}
      &-copyright{font-size: $fs-11; display: flex; justify-content: center; flex-wrap: wrap;margin-block-start:3rem;
        li{width: 50%;margin-inline-end:0rem;}
      }
      &-social{display: none;
        li{margin-block-end: 1.25rem;}
        &-mob{display: flex;margin-block-start:1rem;
          li{margin-inline-end: 0.5rem;}
        }
      }
    } 
  }
}