@import "../Base/Variable.scss";





.businessIntro{
  // &-wrapper{padding-inline: 5.5rem;}
  // h2{display: inline;
  //   span{font-size: $fs-100;text-transform: capitalize;display: inline-block;-webkit-text-fill-color: rgba($white,0.6);}
  //   .vertical{transform: rotate(270deg);transform-origin: 2.875rem 2.063rem; display: inline-block;color: black;-webkit-text-fill-color: rgba($white,0.1);text-transform: uppercase;}
  // }
  h3{font-size: $fs-100;margin: 0.688rem 0rem 0.813rem 0rem;}
  &-image{width: 100%; height: 18.75rem; border-radius: 0.313rem; overflow: hidden;
    img{height: 100%; width: 100%; object-fit: cover;}
  }
  p{margin-block-end: 1.25rem;}
  ul{display: flex;flex-wrap:wrap;padding-block-start:1.438rem;gap: 1.375rem;
    li{width: calc(50% - 0.6875rem);display:flex; align-items: center; color: $white;
      span{height: 2.125rem; width: 2.125rem; border-radius: 50%;background: linear-gradient(90deg, #F3DAFF 1.4%, #BBE2FF 98.95%), #D9D9D9;display: flex; align-items: center; justify-content: center;margin-inline-end: 1.375rem;flex: 0 0 2.125rem;}
    }
  }
}



.whoWeAre{position: relative;padding:5.625rem 0rem 5.3125rem 0rem;
  &-image{position: absolute;width: 40%;height:55rem;right: 0; top: 0;z-index: 2;
    img{width: 100%; height: 100%; object-fit: cover; }
  }
  h2{text-transform: inherit;margin-block-end: 3.875rem;}
  h5{font-size: $fs-30;margin-block-end:1.875rem;}
  p{margin-block-end:1.875rem;}
}



.brand{background-color: $black; padding: 35.125rem 0rem 7.188rem 0rem;
  &-image{height: 29.375rem; width: 100%;
    img{height: 100%; width: 100%; object-fit: contain;}
  }
  .upperText{font-size: $fs-18; color: $white;}
  h5{color: rgba($white,0.6); font-size: $fs-40;margin-block-end: 2rem;}
  .nav{border-block-end: 1px solid rgba($white,0.1);
    &-item{padding-inline-end: 3rem;
      &:focus-visible{outline: none;}
    }
    &-tabs{
      .nav-link{text-align: start;padding: 0rem 0rem 1.875rem 0rem;color: $white;border:0rem;
        &:focus-visible{outline: none;border: 0rem;box-shadow: none;}
        &:hover{border-block-end: 1px solid $white; border-inline: 0rem; border-block-start: 0rem;}
        &.active{background-color: $black;color: $white;border-block-end: 1px solid $white; border-inline: 0rem; border-block-start: 0rem;}
      }
      &:focus-visible{border: 0rem;outline: none;}
    }
  }
  .tab{
    &-content{margin-block-start:1.875rem;
      ul{list-style: disc;color: $white;display: flex; flex-wrap: wrap; margin-block-start: 2.5rem;padding-inline-start: 1.063rem;gap:1.5rem;
        li{width: calc((100% - 2.188rem) / 2);}
      }
    }
  }
}


.team{position: relative;padding-block-start:4rem;
  .container{display: flex;flex-direction: column;align-items: center;}
  h2{font-size: 23.75rem;max-width: 66.688rem; text-align: center;line-height:18.75rem;
    span{font-size: $base-font-size;display: block;text-transform: math-auto;max-width: 30.8rem;margin-inline-start: auto; text-align: start;-webkit-text-fill-color: rgba($black,0.5); font-weight: $base-font-weight;line-height: 1.4;}
  }
  &-list{height: 46.625rem;margin-block-start:2.625rem;position: relative;padding-block-start:4.875rem;width: 100%;
    ul{overflow-y: scroll; height: 100%;padding-block-end:10rem;
      &::-webkit-scrollbar {
        width:0.125rem;background-color: rgba($black,0.3);
      }
      &::-webkit-scrollbar-thumb {
        background-color: $black;
      }
      li{font-family: $heading-font-family; display: flex;align-items: center; padding: 1.563rem 1.3rem 1.563rem 2.25rem; border-block-end: 1px solid rgba($black,0.1);font-size: $fs-20;position: relative;
        span{margin-inline-end: 1.5rem;background-color: rgba($black,0.1); height: 2.875rem; width: 2.875rem; border-radius: 50%; display: flex; align-items: center; justify-content: center; flex: 0 0 2.875rem; }
        h5{margin-block-end: 0rem;min-width: 13.438rem; max-width: 13.438rem;}
        div{margin-inline-start: auto;display: none;
          svg{transition: all 100ms ease-in-out;}
        }
        &:after{content: '';position: absolute; top: 50%; transform: translateY(-50%); left: 0; width: 0.065rem; height: 65%; z-index: 2; background-color: $green;display: none;}
        &:hover{
          &::after{display: block;
          }
          div{
            svg{transform: rotate(-45deg);transition: all 100ms ease-in-out;}
          }
        }
      }
    }
    &:after{content: '';position: absolute; bottom: 0; left: 0; width: 100%; height: 40%; z-index: 2;background: rgb(255,255,255);
      background: linear-gradient(180deg, rgba(255,255,255,0) 20%, rgba(255,255,255,1) 100%);pointer-events: none;}
  }
  &-employee{position: absolute;width: 20rem; height: 21.5rem; top: 0; right: 17.375rem;z-index: 3; border-radius: 0.313rem; overflow: hidden;
    img{height: 100%; width: 100%; object-fit: cover;}
  }

}








