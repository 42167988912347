@import "../Base/Variable.scss";

// Responsive CSS Start

@media only screen and (max-width: 1399px){
  .container{padding-inline: 5%;}
  h1{font-size: 3.438rem;}
  h3{font-size: 3.063rem;}
  h2{font-size: 7.188rem; line-height: 5.5rem;}
  .Homebanner{
    &-topText{font-size: $base-font-size; line-height: 1.2;}
    &-bottomText{font-size: $base-font-size; line-height: 1.2;}
  }
  .globalBanner{
    .phNumber{left: 6%;}
    h1{font-size: 7.5rem;}
  }

  .team{
    h2{font-size: $h2-fs;}
  }

  .whoWeAre{
    h2{font-size: $fs-100;margin-block-end: 2rem;}
    &-image{height: 45rem;}
  }
  .team{
    h2{font-size: $h2-fs;    line-height: 9.75rem;}
  }

  .businessIntro{padding: 8rem 0rem 3.5rem 0rem;
    &-wrapper{padding-inline: 0rem;}
    h2{
      span{font-size: 3rem;
        &.vertical{transform-origin: 22px 31px;    font-size: 34px;}
      }
    }
    h3{
      font-size: 3.25rem;
    }
    &.services{
      h3{font-size: 3.25rem;}
    }
  }
  .points{
    .development{ height: 25rem;}
  }
  .proccess{
    &.bottomSpace{padding-block-end: 9rem;}
  }

  .career{
    h2{max-width: 35rem;}
    .vertical{ transform: rotate(-90deg) translateY(0rem);font-size: 6rem;}
    .sideText{ transform: translateX(-10rem);font-size: 3.75rem;}
  }
  .recognition{
    h3{font-size: 6rem;}
  }
}


@media only screen and (max-width: 1299px) {
  body{font-size: $fs-14;}
  h1{font-size: 2.813rem;}
  .globalBanner{
    h1{font-size: $fs-100;}
    h5{font-size: $fs-22;}
  }

  .homeServices{
    &-info{
      &-about{padding-inline-start: 0.5rem;
        p{
          span{font-size: 1rem;}
        }
        ul{
          li{padding: 1.3rem;
            span{margin-inline-end: 2.125rem;font-size: $fs-25;}
            h5{font-size: $fs-22;margin-block-end: 0rem;min-width: 13.1875rem; max-width: 13.1875rem;}
            p{padding-block-end: 0rem;}
          }
        }
      }
    }
  }
  .team{
    &-employee{right: 9.375rem;}
  }
  .faq{padding-block-start: 3.5rem;
    h3{font-size: $fs-80; line-height: 1;}
   .accordion{
      &-header {
        button{font-size: $base-font-size;padding: 1rem;}
      }
    }
  }
  .whoWeAre{padding: 3.625rem 0rem 0rem 0rem;
    h2{font-size: $fs-80;margin-block-end: 2rem;}
    &-image{height: 35rem;}
  }

  .itSolutions{padding-block-start: 4rem;
    ul{gap: 3rem;
      li{width: calc((100% - 6rem) / 3); padding-block-start: 4.438rem; margin-block-end: 0rem;
        span{font-size: $fs-80;}
        h5{font-size: $fs-22;}
      }
    }
    &-image{
      &-inner{height: 22.5rem;}
    }
  }
  .brand{padding: 12rem 0rem 3.5rem 0rem;}

  .seo{
    &-list{
      li{
        p{font-size: $fs-18;}
      }
      &-number{
        h3{font-size: 5rem;}
        &-svg{height: 2.5rem; width: 2.5rem;}
      }
      &-box{width: 12rem; height: 12rem;}
    }
  }
}


@media only screen and (max-width: 1199px) {

  .primary-button{height: 7rem; width: 7rem; font-size: $fs-14;
    &::after{top: 2.3rem;right: 1rem;height: 1.2rem; width: 1.2rem;}
    }
  .secondary-button{height: 7rem; width: 7rem;font-size: $fs-14;padding-inline-start:0.8rem;overflow:hidden;flex:0 0 7rem;
  &::after{top: 3.5rem;right: 1rem;height: 2rem; width: 2rem;}
  &::before{left: -10px;bottom: -31px;height: 3.563rem;width: 4.5rem;}
  }
  .secondary-button{
    &.dark{flex:0 0 7rem;
      &:after{background-image: url(../../Assets/Images/HomeImages/buttonArrow.svg);top: 2.375rem;right: 0.5rem;}
      &:hover{color: $black;
        &:after{background-image: url(../../Assets/Images/HomeImages/BlackbuttonArrow.svg);top: 1.813rem;right: 0.563rem;}
      }
    }
  }
  .banner{
    &-description{margin-inline-start: 3rem;}
  }
 

  .homeServices{
    &-image{ max-height: 19.125rem;}
    &-info{
      &-about{padding-inline-start: 0.5rem;
        p{
          span{margin-inline-end: 1.4375rem;}
        }
        ul{height: 19.9375rem;
          li{padding: 0.8rem;
            span{margin-inline-end: 1.6rem;font-size: $fs-18;}
            h5{font-size: $fs-18;margin-block-end: 0rem;min-width: 10.1875rem; max-width: 10.1875rem;padding-inline-end: 1.6rem;}
            p{padding-block-end: 0rem;}
            svg{width: 0.938rem;}
          }
        }
      }
    }
  }

  .industry{
    &-wrapper{ padding-block: 3.5rem;
      .secondary-button{flex: 0 0 7rem;}
    }
    &-description{
      h5{font-size: $fs-25;
        &:after{height: 3rem; width: 3rem;}
      }
    }
    .nav{
      &-pills{height: 16rem;}
      &-item{
        margin-block-end: 1rem;
        a{
          span{margin-inline-end: 0.8rem;}
        }
      }
      &-link{font-size: $fs-18;padding:0rem;}
    }
  }

  .OnDemand{padding: 3.75rem 0rem 5rem 0rem;
    &-services{ margin-block-start: 2rem;
      .swiper{margin-block-start: 3.5rem;}
    }
  }

  .technologies{
    &-images{height:28.688rem;
    }
  }

  .stage{
    &-progress{
      ul{
        li{
          .wrapper{
            &-child{
              span{font-size: $fs-40;}
              p{font-size: $base-font-size;}
            }
          }
        }
      }
    }
    &-growth{height: 52.875rem;}
  }

  .project{
    .nav{
      &-item{margin-block-end: 1rem;}
      &-link{font-size: 1.125rem;
        span{ margin-inline-end: 0.6rem;height: 2.3rem;width: 2.3rem;}
      }
    }
    .tab{
      &-pane{
        a{flex: 0 0 7rem;margin-inline-start: 0.438rem;}
        p{display: none;}
        .stickyOne{ height: 25.75rem; width: 12.688rem;}
        .stickyTwo{height: 9.75rem; width: 10.625rem; margin-inline-start: -1.875rem;}
      }
    }
  }

  .products{
    &-swiper{height: 11.25rem; margin-block-start: 2.25rem;
      .swiper{
        &-slide{
          div{height: 17.125rem;}
          p{font-size: $fs-20; padding: 1.3rem;}
        }
      }
    }
  }

  .blog{padding: 11rem 0rem 5rem 0rem;
    &-title{font-size: $fs-18;}
  }

  .HomeContact{
    .secondary-button{flex: 0 0 7rem;margin-inline-start: 2.25rem;
      &:after{top: 2rem;}
    }
  }


  .team{
    &-list{
      ul{
        li{padding: 0.5rem 1rem 0.5rem 1rem;font-size: $base-font-size;
          span{font-size: $base-font-size;}
          h5{font-size: $base-font-size;}
          div{
            svg{max-width: 1.5rem;}
          }
        }
      }
    }
    &-employee{right: 9.375rem;width: 16rem;height: 17.5rem;}
  }

  .proccess{
    &-list{
      .row{margin-block-start: 50px;}
    }
  }
 
  .points{padding: 7.5rem 0rem 4.125rem 0rem;
    h3{ margin-block-end: 0.8rem;}
    ul{
      li{
        h3{margin-inline-end: 0.8rem;}
        span{width: 9rem; font-size: $base-font-size; flex: 0 0 9rem; margin-inline-end: 1rem;}
      }
    }
  }

  .careervideo{
    &-story{width: 100%!important; height: 28.75rem!important;padding: 2.188rem 0rem 5.813rem 0rem;
      iframe{border-radius: 0.625rem; overflow: hidden;
        .video-stream{border-radius: 0.625rem; overflow: hidden;}
      }
    }
    h3{margin-block-end: 1.563rem;}
  }
}


@media only screen and (max-width: 991px) {
 
  .phNumber{font-size: $base-font-size;}
 
  h2{font-size: 5rem; line-height: 4rem;}
  h3{font-size: 2.5rem;}

  .rotate{height: 6.938rem; width: 6.938rem;margin-block-start: 2.125rem;
    &-wrapper{height: 6.938rem; width: 6.938rem;}
    &-link{font-size: $fs-14;color: $black;}
    &-arrow{top: -1.8rem;}
  }
  .globalBanner{
    h1{font-size: $h3-fs;}
    h5{font-size: $fs-18;}
    &-text{padding-inline-start: 3.5rem;justify-content: start;padding-block-start: 2.125rem;}
    &-img{height: 22.75rem;}
    &.inner{
      h1{font-size:  $h3-fs;line-height: 3.75rem;  margin-block-end: 2rem;}
      .globalBanner-text{
        &.head{padding-block-start: 2rem;}
      }
    }
  }
  .career{padding:8rem 0rem 3.5rem 0rem;
    h2{max-width: 25rem;}
    .vertical{font-size: 4.375rem;}
    .sideText{font-size: 2.938rem;transform: translateX(-7rem);margin-block-end: 0rem;}
  }

  .homeServices{
    .servicesName{font-size: $fs-30;}
    &.servicePage{padding-block-start: 8rem!important;}
  }


  .Homebanner{
    &-text{color: $white;}
    &-topText{color: $white;border-block-end: 0.065rem solid $white;
      svg{fill: $white;}
    }
    &-bottomText{color: $white;}
    &-count{color: $white;
      div{
        span{color: rgba($white,0.6);}
      }
    }
  }

  .technologies{
  &-images {
    height: 20.688rem;
    }
  }



  .stage{padding-block-start: 1rem;
    &-name{font-size: $base-font-size;}
    &-head{font-size: $fs-40!important;}
    &-description{padding: 1rem;
    }
  }

  .products{
    $this:&;
    &.servicePage{
      #{$this}{
        &-wrapper{padding-block-start: 5rem;}
      }
    }
    // .swiper{
    //   &-slide{
    //     width: 33.33%!important;
    //   }
    // }
    
  }


  .More {padding-block-start: 12rem!important;}

  .whoWeAre{padding: 2rem 0rem 0rem 0rem;
    h2{font-size: $fs-40;margin-block-end: 0rem;}
    &-image{height: 30rem;}
  }
  .points{
    .development{ height: 17rem;}
  }
  .bottomSpace {
    padding-block-end: 3.75rem;
  }
  .team{
    &-employee{right: 3.5rem;width: 10rem;height: 11rem;}
    &-list{height: 27.625rem;}
  }

  .faq {
    .rotate{display: none;}
    .accordion{
      &-item{
        .accordion-header{
          button{color: $black;font-size: $base-font-size;padding: 1.2rem;
          }
        }
        .accordion-body{font-size: $fs-14;}
      }
    }
  }

  .imageSlider{padding: 4.375rem 0rem 2.3rem 0rem;}
  .swiper{
    &.mySlider{
      .swiper-slide{
        .slide{
          &Text{
            h4{font-size:$fs-30;}
          }
        }
      }
    }
  }
  .platform{padding-block-end: 2rem;}
  .points{
    h3{ margin-block-end: 0.8rem;}
    ul{
      li{padding-inline-start: 6rem;margin-block-start: 2.5rem;
        span{ width: 12rem; margin-inline-end: 1rem; flex: 0 0 12rem;}
        &:before{}
      }
    }
  }
  .industry{
    &-description{margin-inline-start: 2rem;}
  }
}


@media only screen and (max-width: 767px) {
  h1{font-size: 1.625rem;}
  h2{font-size: $fs-30; line-height: 2rem;}
  h3{font-size: $fs-25;}
  .phNumber{display: none;}
  .rotate{display: none;}
  .form{
    &-control{padding: 0.5rem 1rem!important;}
  }
  .globalBanner{  
    &-img{    height: 10.75rem;}
    &-text{padding-inline-start: 0rem;padding-block-start: 0rem;
      p{}
    }
    h1{font-size: $fs-45;}
    &.inner{
      h1{font-size:  $fs-45; line-height: 1;}
      .globalBanner-text{
        &.head{padding-block-start: 0rem;}
      }
    }
  }

  .whoWeAre{padding: 2rem 0rem 0rem 0rem;
    h5{font-size: $fs-18; margin-block-start: 0.6rem;}
    h2{font-size: $fs-40;margin-block-end: 0rem;}
    &-image{height: 14rem;position: relative;width: 100%;}
  }
  .businessIntro{
    h2{
      span{font-size: 3rem;
        &.vertical{transform: rotate(0deg);  font-size: 34px;}
      }
    }
  }
  .imageSlider{ position: relative;padding: 1.5rem 0rem 1.5rem 0rem;}
  .businessIntro{
    h3{font-size: $fs-30;}
    &.services{
      h3{font-size: $fs-30;}
    }
  }

  .team{padding-block-start: 3.5rem;
    &-employee{display: none;}
    &-list{    height: 30.625rem;padding-block-start: 0rem;
      ul{
        li{padding: 0.5rem  0.5rem 0.5rem 0rem;
          span{width: 2rem; height: 2rem; flex: 0 0 2rem;font-size: $fs-11;margin-inline-end: 0.5rem;}
          h5{min-width: 7rem; max-width: 7rem; margin-inline-end:0.5rem;font-size: $fs-14;}
          p{font-size: 0.813rem;}
          div{display: none;}
        }
      }
    }
    h2{font-size: 5rem;line-height: 4.75rem;
      span{font-size: $fs-14;text-align: center;}
    }
  }
  .faq{padding-block-start: 3.5rem;
    h3{font-size: 2.625rem;}
    .accordion{
      &-item{
        .accordion-header{
          span{width: 2rem;height: 2rem;flex: 0 0 2rem;font-size: 0.688rem;margin-inline-end: 0.5rem;}
          button{ font-size: $fs-14;padding: 0.8rem 0.5rem;
          }
        }
      }
    }
  }

  .brand{padding: 9rem 0rem 3.5rem 0rem;
    .tab{
      &-content{
        ul{display: block;
          li{width: 100%; margin-block-end: 0.8rem;}
        }
      }
    }
    .nav{
      &-tabs{
        .nav-link{padding: 0rem 0rem 0.75rem 0rem;
        }
      }
    }
    &-image{height: 11.375rem;}
    .upperText{font-size: $fs-11;}
    h5{font-size: 1.5rem;;}
  }
  .industry{
    &-description{
      h5{font-size: $base-font-size;
      }
    }
    &-image{width: 100%;height: 15.625rem;}
    &-description{width: 100%;}
  }
  .blog {
    padding: 9rem 0rem 3rem 0rem;
}

  .proccess{
    &-list{
      .row{margin-block-start: 25px;}
    }
    &-details{display: flex;
      h3{margin-inline-end: 0.5rem;margin-block-end: 0rem;}
    }
    &.bottomSpace{ padding-block-end: 1.5rem;}
  }

  .businessIntro{
    &-image{height: 11rem;
    }
  }

  .points{padding: 7.5rem 0rem 4.125rem 0rem;
    h3{ margin-block-end: 0.8rem;}
    ul{
      li{display: block;margin-block-start:1rem;    padding-inline-start: 2rem;
        h3{margin-inline-end: 0.8rem;}
        span{width: auto; font-size: $fs-14; margin-inline-end: 1.875rem; flex: 0 0 100%;}
        &:before{font-size: $fs-20;top: 0.75rem;}
      }
    }
  }


  .itSolutions{padding-block-start: 3.75rem;
    ul{gap: 1rem;
      li{width: 100%; padding-block-start: 0.4rem;
        span{font-size: $fs-35;}
        h5{font-size: $fs-14; margin: 1rem 0rem 0.5rem 0rem;}
        &:before{font-size: 3rem;top: -1.25rem;}
      }
    }
    &-image{    height: 4.625rem;    margin-block-start: 3.5rem;
      &-inner{height: 12.5rem;}
    }
  }

  .Homebanner{
   
    &-text{font-size: $fs-14;color: $white;}
    &-description{margin-inline-start: 0rem;
      h1{}
    }
    &-topText{font-size: 0.813rem;color: $white;line-height: 1;border-block-end: 0.065rem solid $white;
      svg{fill: $white;}
    }
    &-bottomText{font-size: 0.813rem;color: $white;}
    &-count{color: $white;
      div{
        span{color: rgba($white,0.6);}
      }
    }
    &-meeting{position:absolute; right: 3.75rem;bottom: 6.25rem;}
   
  
    .video{width: 100% !important;height: 100%!important;position: fixed;left: 0;top: 0;
      video{position: absolute;left: 0;top: 0;object-fit: cover;}
    }
    &:after{content:'';width: 11.6875rem; height: 9.5rem;background-image: url(../../Assets/Images/HomeImages/CurveLine.svg);position: absolute; top: 0;right: 25%;}
    &:before{content:'';width: 6.375rem; height: 21.6875rem;background-image: url(../../Assets/Images/HomeImages/BottomCurve.svg);position: absolute; top: calc(100vh - 11.8125rem);right: 0;z-index: 2;}
  }

  .homeServices {
    .learnMore {margin-block-start: 1rem;
      &:after{display: none;}
    }
  }

  .homeServices{
    &-image{ max-height: 12.125rem;}
    &-info{
      &-about{padding-inline-start: 0.5rem;
        p{
          span{margin-inline-end:0.8rem;font-size: 0.75rem;}
        }
        ul{height: 19.9375rem;
          li{padding: 0.8rem 0.8rem 0.8rem 0rem;
            span{margin-inline-end: 0.625rem;font-size: $base-font-size;}
            h5{font-size: $base-font-size;min-width: 7rem; max-width: 7rem;padding-inline-end: 0.625rem;}
            p{margin-inline-end: 1rem;font-size: 0.75rem;}
          }
        }
      }
    }
  }

  .stage{padding-block-start: 3rem;
    $this:&;
    &.servicePage{
      #{$this}{
        &-growth{
          li{
            &:nth-child(2){border-inline-end: 0.065rem solid rgba($black,0.2)!important;}
          }
        }
        &-progress{
          p{margin-block-start: 0rem!important;}
        }
      }
    }
    &-progress{position: relative;padding-block-end: 1rem;top: 0;
      ul{margin-block-end: 2rem !important; flex-wrap: wrap;
        li{
          .wrapper{
            &-child{
              span{font-size: $fs-30;margin-block-end: 0.8rem;}
              p{font-size: $fs-14;}
            }
          }
        }
      }
    }
    &-growth{height: auto!important;
      li{width: 50%;padding-block-end: 3rem;
        &:nth-child(2){border-inline-end: 0.065rem solid rgba($white,0.2);}
      }
    }
    &-description{padding: 1rem;}
    &-head{font-size: $fs-25!important;}
    &-name{font-size: $fs-14;}
  }



  .project{padding: 3.5rem 0rem 0rem 0rem;
    .nav{
      &-item{margin-block-end: 0.2rem;}
      &-link{font-size: $fs-14;
        span{ margin-inline-end: 0.3rem;height: 2rem;width: 2rem;}
      }
      &-pills{height: 18rem;}
    }
    .tab{
      &-pane{
        a{flex: 0 0 7rem;margin-inline-start: 0.438rem;}
        p{display: none;}
        .stickyOne{ height: 15rem; width: 8rem;}
        .stickyTwo{height: 7rem; width: 5rem; margin-inline-start: -1.875rem;}
      }
    }
  }

  .technologies{overflow-x: hidden;
    &-images {height: auto;
      img{max-height: 230px;}
      }
    }
  
  .HomeContact{padding: 3.5rem 0rem;}


  .industry{
    &-tab{margin-block-start: 1.5rem!important;}
  }



  .products{
    &-swiper{margin-block-start: 0.313rem;
      .swiper{
        &-wrapper{width: 210px;}
        &-slide{ 
          p{font-size: $base-font-size; padding: 0.6rem;}
        }
      }
    }
    &.servicePage{
      .products-wrapper{padding-block-start: 3rem;}
    }
  }

  .More {padding: 10rem 0rem 2.5rem 0rem!important;}

  .blog{
    &-category{
      li{padding: 0.25rem; font-size: $fs-11;margin-block-end: 0.4rem;}
    }
    .swiper{
      &-slide{
        width: 40%!important;
      }
    }
  }
  // .OnDemand{
  //   .swiper{
  //     &-slide{
  //       width: 40%!important;
  //     }
  //   }
  // }

  .points{padding-block:3.75rem;
    .development{ height: 12rem;top: auto; left: auto; position: relative;}
  }

  .newsLetter{background-color: $black;padding: 0rem 0rem 3.75rem 0rem;
    h3{color: $white;margin-block-end: 0.688rem; }
  }
  .platform{padding: 3.75rem 0rem 3.75rem 0rem;}
  .application{padding-block-start: 3.75rem;}
  .serviceCarousel{padding: 1.75rem 0rem 1.75rem 0rem;}

  .proccess{
    &-list{
      &.onlyText{
        .proccess-details{margin-block-end: 2rem;}
      }
    }
  }
  .seo{
    &-list{ margin: 2rem 0rem 3.25rem 0rem;
      li{width: 33.33%;
        p{font-size: $base-font-size;text-align: center;}
      }
      &-number{
        h3{font-size: 4rem;}
        &-svg{bottom: 0;}
      }
      &-box{width: 100%; height: auto;align-items: center;}
    }
  }

  .swiper{
    &.mySlider{
      .swiper-slide{
        .slide{
          &Wrapper{padding-inline:20px;
          }
          &Text{padding-block: 1.5rem;
            h4{font-size: $fs-22;line-height: 1;}
            p{text-align: left;}
          }
        }
      }
    }
    
  }

  .contactUs{
    button{
      &.send{width: 100%;max-width: 100%;padding-block: 0.7rem;}
    }
    &-details{margin-block-end: 0.75rem;
      &-head{font-size: $base-font-size;}
    }
    &-svg{  width: 1.5rem; height: 1.5rem;margin-block-start: 0.375rem;}
  }

  .Location{ padding-block: 3.25rem;
  &-map{height: 12.5rem;border-radius:0.313rem;margin-block-start: 1.5rem;}
}
.industry{
  &-tab{
    .col-md-9{order: 2;margin-block-start: 2rem;}
    .col-md-3{order: 1;
      .nav{
        &-pills{display: flex; flex-wrap: nowrap;height: auto;}
        &-item{margin: 0rem 0.8rem 0rem 0rem;
          a{width: max-content; font-size: $fs-14;padding-block-end:0.625rem;
            span{margin-inline-end: 0.2rem;}
          }
        }
      }
    }
  }
}
.latestBlog{padding: 7.25rem 0rem 0rem 0rem;
  &-img{margin-block-end: 1.125rem;}
}
.contactUs{padding-block-start: 7.688rem;}
.career{padding:8rem 0rem 3.5rem 0rem;
  h2{max-width: 25rem;}
  .vertical{font-size: 2.25rem;transform: rotate(0deg);}
  .sideText{font-size: 1.5rem;transform: translateX(0rem);margin-block-end: 0rem;margin-inline-start: 0.5rem;}
  }
  .recognition{padding: 3.125rem 0rem 3.5rem 0rem;
    h3{font-size: $fs-35;margin-block-end: 1rem;}
    .certified{margin-block-start: 1.5rem;
      li{padding:4px;
        .certificate{height: 5.5rem; width: 5.5rem;
        }
      }
    }
  }
  .careervideo{padding:3.5rem 0rem 3.5rem 0rem ;
    &-story{padding: 0rem 0rem 2rem 0rem;height: 12.75rem!important;}
  }
}



@media only screen and (max-width: 575px) {
  .stage{
    &-progress{
      ul{
        li{width: 50%; padding-block-end: 45%;
          .wrapper{width: 100%; padding-block-start: 100%;left:0;
          }
        }
      }
    }
    &-growth{height: auto!important;
      li{width: 100%;padding-block-end: 3rem; border-inline-end: 0.0625rem solid rgba($white,0.2);
        &:nth-child(2){border-inline-end: 0.065rem solid rgba($white,0.2);}
      }
    }
    &-description{padding: 1rem;}
    &-head{font-size: $fs-25!important;}
    &.servicePage{
      .stage-growth{
        li{border-inline-end: 0.0625rem solid rgba($black,0.2);}
      }
    }
  }

  .blog{
    .swiper{
      &-slide{
        width: 70%!important;
      }
    }
  }

  // .OnDemand{
  //   .swiper{
  //     &-slide{
  //       width: 75%!important;
  //     }
  //   }
  // }

  // .products{
  //   .swiper{
  //     &-slide{
  //       width: 100%!important;
  //     }
  //   }
  // }
  .latestBlog{
    &-img{height: auto;
      img{object-fit: contain;}
    }
    &-title{font-size: $fs-14;margin-block-end: 0.5rem;}
  }
  .seo{
    &-list{ margin: 2rem 0rem 3.25rem 0rem;
      li{width: 50%;
        p{font-size: $fs-14;text-align: center;}
      }
      &-number{
        h3{font-size: 3rem;}
        &-svg{bottom: 0;height: 1.875rem; width: 1.875rem;}
      }
      &-box{
        &:hover{transform: scale(1);margin-top: -1.875rem;}
      }
    }
  }
  .businessIntro{
    ul{display: block;
    li{width: 100%;margin-block-end: 1rem;}
    }
  }
  .industry{
    &-description{margin-inline-start: 0rem;}
  }
}

