@import "../Base/Variable.scss";


//-----------------Only Banner CSS--------------------
.Homebanner{ height: 100vh;position: relative;
  .container{height: 100vh;display: flex; align-items: center;width: 100%;}
  &-text{position: relative;z-index: 1;height:100%; width: 100%;font-family: $heading-font-family;display:flex; align-items: center;}
  &-description{margin-inline-start: 5.5625rem;
    h1{margin-block-end: 1.125rem;}
  }
  &-topText{max-width: 14.3125rem;display: flex;padding-block-end: 0.5625rem; border-block-end: 0.0625rem solid $black;margin-block-end: 1.125rem;line-height: 1.5625rem;font-size: $fs-19;color: $black;}
  &-bottomText{max-width: 19.25rem;font-size: $fs-18;line-height: normal;margin-block-end: 2rem;color: $black;}
  &-count{font-size: $fs-22;display:flex;max-width: 19.25rem; align-items: end;
    div{width: 50%;
      span{font-size: $base-font-size;color: rgba($black,0.5);display: block; line-height:1.25rem;}
    }
  }
  &-meeting{position:absolute; right: 3.75rem;bottom: 6.25rem;}
 

  .video{width: 100% !important;height: 100%!important;position: fixed;left: 0;top: 0;
    video{position: absolute;left: 0;top: 0;object-fit: cover;}
  }
  &:after{content:'';width: 11.6875rem; height: 9.5rem;background-image: url(../../Assets/Images/HomeImages/CurveLine.svg);position: absolute; top: 0;right: 25%;}
  &:before{content:'';width: 6.375rem; height: 21.6875rem;background-image: url(../../Assets/Images/HomeImages/BottomCurve.svg);position: absolute; top: calc(100vh - 11.8125rem);right: 0;z-index: 2;}
}





//-----------------Only Demand Services CSS--------------------
.OnDemand{position: relative; z-index: 1;background-color: $black;padding: 7.125rem 0rem 13.063rem 0rem;
  &-services{margin-block-start: 3.625rem;
    &-card{
      p{color: rgba($white,0.5);}
      div{margin-block-start: 0.438rem;width: 100%;height: 16.125rem;border-radius:0.313rem; overflow: hidden;
        img{height: 100%; width: 100%; object-fit: cover;}
      }
    }
  }
  &-book{margin-block-start: 2.5rem;display: flex; align-items: end;
  div{margin-inline-start: 3.25rem;
    p{font-size: $fs-22; color: $white;}
    span{color: rgba($white,0.5);}
    }
  }
}
// .swiper{
//   &.outSlider{margin-inline-end: calc((100% - 1320px)/2);}
// }



//-----------------Only Stage CSS--------------------
.stage{position: relative; z-index: 1; background-color: $black; padding-block-start:2.75rem;font-family:$heading-font-family;
  &-progress{position: absolute; left: 0;width: 100%; top: 2.75rem;
    ul{display: flex;margin-block-end:15px!important;
      li{position: relative; width: 25%; padding-block-end: 28%;
        .wrapper{position: absolute;width: calc(100% + 26px);height: auto;padding-block-start: calc(100% + 1.5rem);border: 0.0625rem solid rgba($white,0.2);border-radius: 50%;left: -13px;
          &-child{position: absolute; left: 0; top: 0; width: 100%;height: 100%;overflow: hidden;display: flex; flex-direction: column;align-items: center;justify-content: center;
            span{font-size: $fs-80;color: rgba($white,0.5);line-height: 1;margin-block-end: 0.813rem;}
            p{text-align: center;font-size: $fs-25;line-height: 1.2;}
          }
        }
      }
    }
  }
  &-head{font-size: $fs-40;color: $white;font-family:$heading-font-family;line-height: 1;}
  &-growth{display: flex;flex-wrap:wrap;border-block-end: 0.0625rem solid rgba($white,0.2);margin-block-start:1.75rem;height:59.375rem;
    li{width: 25%;display: flex; flex-direction: column; justify-content: end; border-inline-start: 0.0625rem solid rgba($white,0.2);
    &:last-child{border-inline-end: 0.0625rem solid rgba($white,0.2);}
    p{text-align: center;margin-block-end: 0.938rem!important;font-family: $body-font-family;}
    }
  }
  &-name{font-size:$fs-20;color: rgba($white,0.5);}
  &-description{padding:2.063rem  2.25rem 2.938rem 2.25rem;border-block-start: 0.0625rem solid rgba($white,0.2);display: flex; align-items: center; flex-direction: column;position: relative;
    &:after{content: ''; position: absolute; top: -0.438rem; width: 0.75rem; height: 0.75rem; border-radius: 50%; background-color: $green;filter: blur(0.188rem);}
    a{color: $white;
      span{margin-inline-start: 0.625rem;}
    }
  }
}


//-----------------Only Project Case CSS--------------------
.project{position: relative; z-index: 1; background-color: $black;color: rgba($white,0.5);padding: 6.25rem 0rem 5.438rem 0rem;font-family:$heading-font-family;
  &-head{color: $white;margin-block-end: 1.875rem!important;}
  .nav{
    &-pills{height: 25rem;display: block;overflow-y: scroll;position:relative;
      &:after{} 
      &::-webkit-scrollbar {
        width: 0.125rem;background-color: rgba($white,0.3);
      }
      &::-webkit-scrollbar-thumb {
        background-color: $green;
      }
    }
    &-item{margin-block-end: 2.3rem;}
    &-link{color: rgba($white,0.5);font-size: $fs-22;padding:0rem;display: flex; align-items: center;
      span{margin-inline-end: 1.5rem;height: 2.875rem; width: 2.875rem;display: flex; align-items: center; justify-content: center; border-radius: 50%;}
      &.active{color: $white!important;background-color: $black!important;
        span{background-color: rgba($white,0.1);}
      }
      &:hover{color:rgba($white,0.7);}
    }
  }
  &-wrapper{position: relative;
    &::after{content: '';height: 100%; width: 100%;background: rgb(0,0,0);
      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 71%);position: absolute; left: 0; top: 0;pointer-events: none;}
  }
  .tab{
    &-pane{position: relative;
      h2{position: absolute; top: 0; right: 0;text-align: end;}
      .stickyOne{height: 38.75rem; width: 19.688rem;
        img{height: 100%; width: auto; object-fit: contain;}
      }
      .stickyTwo{height: 17.75rem; width: 10.625rem;margin-inline-start:-1.875rem;
        img{height: 100%; width: auto; object-fit: contain;}
      }
      p{margin-inline-start:2.313rem;font-family: $body-font-family;}
      a{flex: 0 0 9.75rem;margin-inline-start:3.438rem;}
    }
  }
}


//-----------------Only Blog CSS--------------------
.blog{position: relative; z-index: 1;background-color: $black;padding:21.438rem 0rem 12.5rem 0rem;
  &-head{
    h3{color: $white!important;}
    a{margin-block-start: 2rem;}
  }
  &-card{background-color: $black;font-family: $body-font-family;}
  &-img{width: 100%;height: 12.188rem; margin-block-end:0.625rem;
    img{height: 100%; width: 100%; object-fit: cover;}
  }
  &-create{display: flex; justify-content: space-between;margin-block-end:1.25rem;
    a{background-color: $white;padding: 0.5rem 0.938rem;font-size: $fs-11;line-height: 1;font-family: $heading-font-family;color: $black;}
    span{padding: 0.5rem 0.938rem;font-size: $fs-11;line-height: 1;font-family: $heading-font-family;color: rgba($white,0.6);}
  }
  &-title{color: $white;text-transform: uppercase;font-size: $fs-30; white-space: nowrap; width: 100%; overflow: hidden;text-overflow: ellipsis; }
  &-post{display: flex;
    span{font-size: $fs-35;color: rgba($white,0.5);margin-inline: 0.313rem;line-height: 0.625rem;}
  }
  &-category{margin-block-start:1.875rem; display: flex;
    flex-direction:row;flex-wrap:wrap;
    li{margin-inline-end: 0.625rem;padding: 0.5rem 0.938rem;background-color: rgba($blue,0.5);color: rgba($white,0.5);
      &.active{color: $white;background-color: $blue;}
    }
    
  }
}


//-----------------Only Home Contact CSS--------------------
.HomeContact{position: relative; z-index: 2;background-color: $white;padding: 5rem 0rem 5.625rem 0rem;
  .secondary-button{flex: 0 0 9.75rem;margin-inline-start: 4.25rem;}
  &-image{position: absolute; right: 0;top: 0;width: 100%;height: 38.938rem;max-width: 22.25rem;z-index: 2;
    img{height: 100%; width: 100%; object-fit: cover;}
  }
  .secondary-button{
    &:after{top:3rem;}
  }
}





